<script setup lang="ts">
import {createSingleObserver} from "@km/js/createSingleObserver";
import {onMounted} from "vue";

const props = defineProps({
      datasrc: {
        type: String,
        required: true
      },
      altText: {
        type: String,
        required: true
      }
    }
)

const imageref = ref();
onMounted(()=>{
  watch(()=>props.datasrc, ()=>{
    createSingleObserver(imageref.value);
  }, {immediate:true})
});



</script>

<template>
  <div>
  <img ref="imageref" width="331" height="350" :data-src="datasrc" :alt="altText"
       :title="altText"/>
  </div>
</template>

<style scoped lang="scss">

</style>